import React from "react";
import {
    Grid,
    Box,
    IconButton,
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MapContainer, TileLayer } from "react-leaflet";
import RoutineMachine from './RoutineMachine'
export default function ConsultarRota() {

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: 10 }}
        >


            <Box
                sx={{
                    width: 1500,
                    height: 750,
                    marginBottom: 16,
                    border: "3px solid black",
                    position: "relative",
                }}
            >

                <MapContainer
                    center={[-15.7891, -47.8850]}
                    doubleClickZoom={false}
                    id="mapId"
                    zoom={14}
                    style={{ width: "100%", height: "100%" }} // O mapa ocupa todo o Box
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <RoutineMachine />
                </MapContainer>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: 15,
                        right: 15,
                    }}
                >
                    <FilterAltIcon sx={{ fontSize: 40 }} />
                </IconButton>
            </Box>
        </Grid>
    );
}
