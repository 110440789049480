import * as React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./footer/Footer";
import { Grid, Stack } from "@mui/material";

export default function MainAppBar() {

  React.useEffect(() => {
    const token = localStorage.getItem("accessToken")
    if (!token) {
      window.location.href = "/login";
    }
  }, [])

  return (
    <React.Fragment>
      <Stack sx={{ height: '100vh' }} spacing={2}>
        <Header />
        <Grid
          container
          sx={{
            flex: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "100%",
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
        <Footer />
      </Stack>
    </React.Fragment>
  );
}
