import './Setings.css'
import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import { Container, Stack, Button, Typography, TextField, Slider, Grid, Box, Alert } from "@mui/material";
import Service from "../services/Service";
import AlertDialog from './AlertDialog';
import CircularProgress from '@mui/material/CircularProgress';

function Settings() {
    const [checkedCalculo, setCheckedCalculo] = React.useState(true);
    const [checkedAPI, setCheckedAPI] = React.useState(false);
    const [apenasApiLocalidadeGoogle, setApenasApiLocalidadeGoogle] = React.useState(false);
    const [checkDuplicidade, setCheckDuplicidade] = React.useState(false);

    const [numeroProcessos, setNumeroProcessos] = React.useState(0);
    const [numeroPods, setNumeroPods] = React.useState(0);
    const [distancia, setDistancia] = React.useState(30);

    const [disableConfirm, setDisableConfirm] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [carregarSetrings, setCarregarSetrings] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState({ tipo: "", message: "mensagem não anexada" }) // "Informe o parâmetro de processamento desejado.
    const [openError, setOpenError] = React.useState(false)

    React.useEffect(() => {
        if (carregarSetrings) {
            buscaSettings();
        }

    }, [carregarSetrings]);

    function buscaSettings() {
        setLoading(true)
        Service.GET('/api/settings/find')
            .then(r => {

                if (r != null) {
                    setLoading(false)
                    setCheckedAPI(r.isAPI);
                    setCheckedCalculo(r.isCalculo);
                    setNumeroPods(r.numeroPods);
                    setNumeroProcessos(r.numeroProcessos);
                    setDistancia(r.distancia);
                    setCarregarSetrings(false);
                    setApenasApiLocalidadeGoogle(r.apenasApiLocalidadeGoogle);
                    setCheckDuplicidade(r.checkDuplicidade);
                } else {

                }
            });

    }

    const handleSubmit = (event) => {
        if (!checkedCalculo && !checkedAPI && !apenasApiLocalidadeGoogle && !checkDuplicidade) {
            setOpenError(true);
            setMensagem({ tipo: "error", message: "Informe o parâmetro de processamento desejado." });
            window.setTimeout(() => {
                setOpenError(false);
            }, 5000)

            return;
        }
        event.preventDefault()
        setDisableConfirm(true);
        // console.log("---")
        const dado = {
            numeroProcessos: numeroProcessos,
            numeroPods: numeroPods,
            distancia: distancia,
            isAPI: checkedAPI,
            isCalculo: checkedCalculo,
            apenasApiLocalidadeGoogle: apenasApiLocalidadeGoogle,
            checkDuplicidade: checkDuplicidade
        }
        Service.POST('/api/settings/save', dado)
            .then(r => {
                // console.log(r);
                setDisableConfirm(false);
                setOpen(true);
                //buscaSettings();
            });
    }

    const handleSliderChange = (event, newValue) => {
        setDistancia(newValue);
    };

    const handleNumeroProcessos = (e) => {
        setNumeroProcessos(e.target.value)
    }

    const handleNumeroPods = (e) => {
        setNumeroPods(e.target.value)
    }

    function valuetext(value) {
        return `${value} km`;
    }

    const handleChange = (e) => {
        // console.log(e.target.name );
        if (e.target.name === 'API') {
            setCheckedAPI(e.target.checked);
        } else if (e.target.name === 'APILocalGoogle') {
            setApenasApiLocalidadeGoogle(e.target.checked)
        } else if (e.target.name === 'check') {
            setCheckDuplicidade(e.target.checked);
        } else {
            setCheckedCalculo(e.target.checked);
        }
    }

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '5vh',
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="sm" style={{ marginBottom: '40px', marginTop: '20px' }}>
            {/* <Grid container spacing={2} mt={8}> */}
            {openError &&
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} >
                    <Alert severity={mensagem.tipo}>
                        {mensagem.message}
                    </Alert>
                </Stack>
            }
            {/* <Grid item xs={6}></Grid> */}
            {/* </Grid> */}
            <Stack spacing={1} className='stack'>
                <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginTop: '20px' }} className='margin10px'>
                    Configuração
                </Typography>
                <form >
                    <div className='margin10px'>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <h4>Delimitar distância</h4>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={4}>
                                <Slider
                                    value={distancia}
                                    min={0}
                                    max={100}
                                    onChange={handleSliderChange}
                                    getAriaValueText={valuetext}
                                    valueLabelFormat={valuetext}
                                    aria-labelledby="input-slider"
                                    valueLabelDisplay="auto"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <span className='km'>{valuetext(distancia)} </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='margin10px'>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox name="CALC" onChange={handleChange} checked={checkedCalculo} />
                                    } label="Fazer Calculo Ponto a Ponto" />
                                    <FormControlLabel control={
                                        <Checkbox name="API" onChange={handleChange} checked={checkedAPI} />
                                    } label="Buscar rota via API-GOOGLE" />
                                    <FormControlLabel control={
                                        <Checkbox name="APILocalGoogle" onChange={handleChange} checked={apenasApiLocalidadeGoogle} />
                                    } label="Apenas Localidade Google" />
                                    <FormControlLabel control={
                                        <Checkbox name="check" onChange={handleChange} checked={checkDuplicidade} />
                                    } label="Checar duplicidade inscrição" />


                                </FormGroup>
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <Button variant="contained" endIcon={<SendIcon />} disabled={disableConfirm} onClick={handleSubmit} >
                            Confirmar
                        </Button>
                    </div>
                </form>
            </Stack>
            <AlertDialog open={open} setOpen={setOpen} children={"Configuração atualizada com sucesso!!"} title={"INEP"} />
        </Container>
    )
}

export default Settings;