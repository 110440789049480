import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Service from '../../services/Service';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import CircularProgress from '@mui/material/CircularProgress';



export default function DialogDetalhesGrafico(props) {
    const [open, setOpen] = React.useState(props.open);
    const { uf, distancia } = props;
    const [descricaoDialog, setDescricaoDialog] = React.useState("");
    const [lista, setLista] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [loading, setLoading] = React.useState(false);

    const [ordem, setOrdem] = React.useState({
        campo: '',
        ordem: 'asc'
    });
    const dado = {
        uf: uf,
        distancia: distancia,
        page: 0,
        rowsPerPage: rowsPerPage,
        ordem: ordem
      } 

    React.useEffect(() => {
       buscaLocalProva(dado);
    //    console.log(distancia)
        if(!distancia){
            setDescricaoDialog("Inscrições sem distância definida")
        } else if (distancia === "10to20") {
            setDescricaoDialog("Inscrições com distância entre 10 e 20 km")
        } else if (distancia === "20to30") {
            setDescricaoDialog("Inscrições com distância entre 20 e 30 km")
        } else if (distancia === "30to40") {
            setDescricaoDialog("Inscrições com distância entre 30 e 40 km")
        } else if (distancia === "50mais") {
            setDescricaoDialog("Inscrições com distância superior a 50 km")
        }
    
    }, [distancia, descricaoDialog, rowsPerPage, ordem])

    const handleClose = () => {
        setOpen(false);
    };

    function buscaLocalProva(dado){
        setLoading(true)
        Service.POST('/api/localProva/detalhamentoGrafico', dado)
        .then(r => {
            setLoading(false)
            setTotal(r.count)
            setLista(r.lista);
        });
    }

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
          or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
          or.campo = coluna;
          or.ordem = 'asc';
        }
        setOrdem(or);
      }

      const montaLineColor = (row) => {
        if (!row.concluido) return "#76B5E5";
        if (row.rotas == null) return "#DADADA";
        return null;
      }

      const handlerMkM = (r) => {
        if (r >= 1000) {
          r = r / 1000 + " Km"
        } else {
          r = r + " M";
        }
        return r;
    
      }

      const handleChangePage = (event, newPage) => {
        const dado = {
          uf: uf,
          distancia: distancia,
          page: newPage,
          rowsPerPage: rowsPerPage,
          ordem: ordem
        }
        buscaLocalProva(dado);
    
        setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const dado = {
          uf: uf,
          distancia: distancia,
          page: 0,
          rowsPerPage: parseInt(event.target.value, 10),
          ordem: ordem
        }
        buscaLocalProva(dado);
      };
    if (loading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '5vh', 
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <React.Fragment>
            <Dialog
                maxWidth={"max-width"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Typography style={{ textAlign: "center", margin:'5px', fontSize: 24 }}>{descricaoDialog}</Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon style={{ color: '#1864c5' }} />
                </IconButton>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table" size={'small'}>
                            <TableHead style={{ backgroundColor: "#044785" }}>
                                <TableRow >
                                    <TableCell sortDirection={false} style={{ color: "white", padding: '12px' }}>
                                        <TableSortLabel
                                            active={ordem.campo === 'sg_uf'}
                                            direction={ordem.ordem}
                                            onClick={(e) => createSortHandler('sg_uf')}>
                                            UF
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={false} style={{ color: "white" }}>
                                        <TableSortLabel
                                            active={ordem.campo === 'co_municipio'}
                                            direction={ordem.ordem}
                                            onClick={(e) => createSortHandler('co_municipio')}>
                                            Município
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={false} style={{ color: "white" }}>
                                        <TableSortLabel
                                            active={ordem.campo === 'inscricao'}
                                            direction={ordem.ordem}
                                            onClick={(e) => createSortHandler('inscricao')}>
                                            Inscrição
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={false} style={{ color: "white" }}>
                                        <TableSortLabel
                                            active={ordem.campo === 'inscricao'}
                                            direction={ordem.ordem}
                                            onClick={(e) => createSortHandler('inscricao')}>
                                            Distância Ponto a Ponto
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lista && lista.map((row, index) => (
                                    <TableRow sx={{ backgroundColor: montaLineColor(row) }}
                                        key={index}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.sg_uf}</TableCell>
                                        <TableCell>{row.municipio_prova}</TableCell>
                                        <TableCell>{row.inscricao}</TableCell>
                                        <TableCell>{handlerMkM(row.rotas[0].distanceMeters)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>


                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25
                                            // ,{ label: 'All', value: -1 }
                                        ]}
                                        colSpan={3}
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage={"Linhas por Página"}
                                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'registro por pagina',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}