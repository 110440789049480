const Service ={

    GET: function (url){
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                //Authorization: `Bearer ${localStorage.accessToken}`
            }
          }).then( r => {
            if(r.status === 401){

                Service.gotoLogin();
            }
            if (r.ok) {
                
                return r.json();
            }else{
                if( r.status === 500){
                    throw "Servidor não responde apropriadamente.";
                }
                return r.json().then(erro =>{
                    console.error("Erro da requisicao: ",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        // console.log("aqui....")
                        throw erro;
                    }
                });
            }
        });
    },
    POST:function (url, dado){
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                //Authorization: `Bearer ${localStorage.accessToken}`
            }
          }).then( r => {
            if(r.status === 401){

                Service.gotoLogin();
            }
            if (r.ok) {
                
                return r.json();
            }else{
                if( r.status === 500){
                    throw "Servidor não responde apropriadamente.";
                }
                return r.json().then(erro =>{
                    console.error("Erro da requisicao: ",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        // console.log("aqui....")
                        throw erro;
                    }
                });
            }
        });
    },
    POSTReport: function(url, dado){
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return response.json().then(erro =>{
                    console.error("---->",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        throw erro;
                    }
                });
            }
        });
    },
    gotoLogin: function(){
        localStorage.clear();
        throw {
            tipo: 'alertDialog',
            mensagem: 'Realize o login novamente.',
            titulo: "Sua sessão expirou!",
            action: ()=> window.location.href = "/login"
        };
    }
}
export default Service;