import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import './Header.css'

import React from "react";

export default function Header() {
  return (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#222", height: 50, padding: "0 50px" }}
      >

        <div className="barra-brasil">
          <a href="http://www.brasil.gov.br/" className="pic-gov" title="GovBR">GovBR</a>
        </div>
        <Box
          component="nav"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              margin: 0,
              padding: 0,
              height: "100%",
            }}
          >
            {[
              {
                href: "http://www.acessoainformacao.gov.br",
                label: "Acesso à informação",
              },
              {
                href: "http://brasil.gov.br/participacao-social",
                label: "Participe",
              },
              { href: "https://servicos.gov.br/", label: "Serviços" },
              {
                href: "http://www4.planalto.gov.br/legislacao/",
                label: "Legislação",
              },
              {
                href: "/",
                label: "Órgãos do Governo",
                id: "link-orgaos",
              },
            ].map((item, index) => (
              <React.Fragment key={index}>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <a
                    href={item.href}
                    id={item.id}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#044785")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                  >
                    {item.label}
                  </a>
                </li>
                {index < 4 && (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 10px",
                    }}
                  >
                    |
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </Box>
      </Grid>

      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#044785", height: 35, padding: "0 50px" }}
      >
        <Box
          component="img"
          src="logo_inep.svg"
          alt="Logo"
          sx={{ height: "50%" }}
        />
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#cccccc",
          height: 42,
          padding: "0 50px",
          width: "100%",
        }}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "auto" }}
        >
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              margin: 0,
              padding: 0,
              height: "100%",
              gap: "20px",
            }}
          >
            {[
              { href: "/configurarProcessos", label: "Configurar Processos" },
              { href: "/graficos", label: "Gráficos" },
              { href: "/mapaInscricoes", label: "Mapa Inscrições" },
              { href: "/localProva", label: "Inscrições" },
              /* { href: "/resultados", label: "Resultados" }, */
              { href: "/consolidado", label: "Consolidado" },
            ].map((item, index) => (
              <li
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <a
                  href={item.href}
                  id={item.id}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#044785")}
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          <Tooltip title="Configuração">
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                color: "black",
              }}
              href="/settings"
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

    </Grid>
  );
}
