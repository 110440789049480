import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, TableFooter, TablePagination, Typography } from '@mui/material';
import Service from '../../services/Service';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 3
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    paddingBottom: '8px'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}


export default function GenericTable({ click, dado }) {
    const [open, setOpen] = React.useState(false);
    const [dados, setDados] = React.useState("")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleOpen = (item) => {
        click(item)
    };

    const handleClose = () => setOpen(false);

    function formataData(dt) {
        if (!dt) return '';
        return new Date(dt).toLocaleString('pt-BR', { timezone: 'UTC' })
    }

    function handleVerMais(ufs) {
        setDados(ufs + ",")
        setOpen(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function formataUF(filtro) {
        if (!filtro || !filtro.UF) return '';
        if (filtro.UF.length > 3) {
            let primeirasUFs = filtro.UF.slice(0, 3).join(', ');
            return (
                <>
                    <Typography>
                        {primeirasUFs}
                        <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => handleVerMais(filtro.UF)}
                        >
                            ...ver mais
                        </span>
                    </Typography>
                </>
            )
        }
        return filtro.UF.join(', ');
    }

    function formataMunicipio(bloco) {
        const filtro = bloco.filtro;
        if (!filtro || !filtro.CIDADE) return '';

        if (filtro.CIDADE.length > 3) {
            let primeirasCidades = filtro.CIDADE.slice(0, 3).map(cidade => bloco[cidade]).join(', ');
            return (
                <>
                    <Typography>
                        {primeirasCidades}{' '}
                        <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => handleVerMais(filtro.CIDADE.map(cidade => bloco[cidade]))}
                        >
                            ...ver mais
                        </span>
                    </Typography>
                </>
            );
        }

        return filtro.CIDADE.map(cidade => bloco[cidade]).join(', ');
    }

    function montaStatus(row) {
        if (row.pausado) return "Pausado";
        if (row.cancelado) return "Cancelado";
        return row.estado;
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead >
                    <TableRow>
                        <StyledTableCell style={{ backgroundColor: "#044785" }}>UF</StyledTableCell>
                        <StyledTableCell style={{ backgroundColor: "#044785" }} align="right">Cidade</StyledTableCell>
                        <StyledTableCell style={{ backgroundColor: "#044785" }} align="right">Status</StyledTableCell>
                        <StyledTableCell style={{ backgroundColor: "#044785" }} align="right">Data e Hora</StyledTableCell>
                        <StyledTableCell style={{ backgroundColor: "#044785" }} align="right">Detalhar</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? (dado || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : dado || []
                    ).map((row, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                                {formataUF(row.filtro)}
                            </StyledTableCell>
                            <StyledTableCell align="right">{formataMunicipio(row)}</StyledTableCell>
                            <StyledTableCell align="right">{montaStatus(row)}</StyledTableCell>
                            <StyledTableCell align="right">{formataData(row.dtCriacao)}</StyledTableCell>
                            <StyledTableCell align="right">
                                <IconButton aria-label="Example" onClick={() => handleOpen(row)} >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={3}
                            count={dado?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Linhas por Página"}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'registro por pagina',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={headerStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Detalhamento
                        </Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                ml: 0,
                                width: '100%',
                                maxHeight: '300px',
                                overflowY: 'auto',
                                wordBreak: 'break-word',
                            },
                        }}
                    >
                        <Typography id="modal-modal-description" sx={{}}>
                            {dados}
                        </Typography>

                    </Box>
                </Box>
            </Modal>
        </TableContainer>
    );
}