import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Alert, IconButton, Typography, Stack } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import Service from "../../services/Service";
import GenericModal from "../genericModal/GenericoModal";
import FilterModal from "../genericModal/FilterModal";
import GerarRelatorioDialog from "../processo/GerarRelatorioDialog";
import DialogDetalhesGrafico from "./DialogDetalhesGrafico";
import ReactDOM from 'react-dom/client';
import DialogDetalhesGraficoPercentual from "./DialogDetalhesGraficoPercentual";
import DialogDetalhesTotalIncricoes from "./DialogDetalhesTotalIncricoes";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend

);

const options = {
    indexAxis: 'x', // eixo x para barras verticais
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'Acima de 30 km',
        },
    },
    scales: {
        x: {
            beginAtZero: true,
            ticks: {
                autoSkip: false, // Garante que todos os rótulos sejam exibidos
            },
        },
        y: {
            beginAtZero: true,
        },
    },
};

const optionsPie = {
    responsive: true,
    maintainAspectRatio: false
}

const data = {
    labels: [], // Exemplo de rótulos específicos
    datasets: [
        {
            label: 'Total Processada',
            data: [], // Dados correspondentes a cada rótulo
            borderColor: 'rgba(1, 102, 255, 0.2)',
            backgroundColor: 'rgba(1, 102, 255, 1)',
        }
    ],
};

const dataPie = {
    
    datasets: [
        {
            label: '# of Votes',
            data: [],
            backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const titleStyle = {
    fontWeight: '600', // Bold title
    marginBottom: '8px', // Space between title and spinner
    color: '#333', // Darker text for better readability
};

const spinnerStyle = {
    color: '#1976d2', // Custom spinner color
    marginTop: '16px', // Margin for separation
};

export default function GraficosPage2() {
    const [inscricoes, setInscricoes] = useState({ options: options, data: data });
    const [porcento, setPorcento] = useState({ options: options, data: data });
    const [acima30km, setAcima30Km] = useState({ options: options, data: data });
    const [showGrafico, setShowGrafico] = useState(false);
    const [dadoGrafico, setDadoGrafico] = useState(null);
    const [totalInscricoes, setTotalInscricoes] = useState(dataPie);
    const [open, setOpen] = useState(false);
    const [openFilter, setOpenFilter] = useState(false)
    const [selectedDistance, setSelectedDistance] = useState('0to10');
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [dados, setDados] = useState([]);
    const [ openGerarRelatorio, setOpenGerarRelatorio] = React.useState(false);
    const [ tipoRelatorio, setTipoRelatorio] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [mensagem, setMensagem] = useState({ tipo: "", message: "mensagem não anexada" })
    const [openError, serOpenError] = useState(false)

    useEffect(() => {
        setLoading(true)
        Service.GET('/api/localProva/graficoV2')
            .then(r => {
                setLoading(false)
                setDadoGrafico(r);
                if (r && r.status === 'CONCLUIDO') setShowGrafico(true);
                if (r && r.status === 'ATUALIZANDO') setShowGrafico(true);
                montaGraficos(r);
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                setShowGrafico(true)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao gerar Gráficos"
                })
            })
    }, [selectedFilter])

    const openDialogDetalhesGrafico = (uf) => {
        const root = ReactDOM.createRoot(
        document.getElementById('rederizarDialogDetalhesGrafico')
      );

      root.render(<DialogDetalhesGrafico 
        open={true}
        uf={uf}
        distancia={selectedDistance}/>);
    }

    const openDialogDetalhesGraficoPercentual = (uf) => {
        const root = ReactDOM.createRoot(
        document.getElementById('rederizarDialogDetalhesGrafico')
      );

      root.render(<DialogDetalhesGraficoPercentual
        open={true}
        uf={uf}
        distancia={selectedDistance}/>);
    }

    const openDialogDetalhesTotalIncricoes = (uf) => {
        const root = ReactDOM.createRoot(
        document.getElementById('rederizarDialogDetalhesGrafico')
      );

      root.render(<DialogDetalhesTotalIncricoes
        open={true}
        uf={uf}/>);
    }


    function montaGraficos(g) {
        const d = g.grafico;
        // console.log(d)
        const nomeUf = d.lstInscricao.map(insc => insc.nome);
        setDados(nomeUf);
        let cidadeLabel = [];
        let ufTotalProcessada = [];
        let superiorLabel = [];
        let superiorData = [];
        let porcenLabel = [];
        let porcenData = [];
        let total = 0;
        let superiorQtd = 0;
        let arryPorcento = [];
        let listaFiltrada;
        let superio30km = 0;
        let inferior30km = 0;

        // filtro de UFs
        const ufsSelecionadas = selectedFilter.length > 0 ? selectedFilter.map(uf => uf.toUpperCase()) : d.lstInscricao.map(insc => insc.nome.toUpperCase());

        // top 20 - % acima de 30km
        if (d.lstMaior30Km && d.lstMaior30Km.length > 0) {
            for (let x in d.lstMaior30Km) {
                const supe = d.lstMaior30Km[x];
                const insc = d.lstInscricao[x];
                superio30km += supe.qtd
               
                if (ufsSelecionadas.includes(supe.nome.toUpperCase())) {
                    if (supe.nome === insc.nome) {
                        arryPorcento.push({ nome: supe.nome, qtd: ((supe.qtd / insc.qtd) * 100).toFixed(2) });
                    } else {
                        console.error(">>>>>>>>>>>>Error");
                    }
                }
            }
            for(let x in d.lstMenor30Km){
                const supe = d.lstMenor30Km[x];
                inferior30km += supe.qtd
            }
        }
        // console.log(superio30km, inferior30km)

        if (arryPorcento && arryPorcento.length > 0) {
            arryPorcento.sort((a, b) => b.qtd - a.qtd);
            for (let x in arryPorcento) {
                const porcento = arryPorcento[x];
                porcenLabel.push(porcento.nome);
                porcenData.push(porcento.qtd);
            }
        }

        if (d.lstInscricao && d.lstInscricao.length > 0) {
            d.lstInscricao.sort((a, b) => b.qtd - a.qtd);
            for (let x in d.lstInscricao) {
                const insc = d.lstInscricao[x];

                if (ufsSelecionadas.includes(insc.nome.toUpperCase())) {
                    cidadeLabel.push(insc.nome);
                    ufTotalProcessada.push(insc.qtd);
                    total += insc.qtd;
                }
            }
        }

        // Filtrar por distância
        listaFiltrada = d.lstMaior30Km
        if (selectedDistance === "10to20") {
            listaFiltrada = d.lstSuperior10To20Km;
        } else if (selectedDistance === "20to30") {
            listaFiltrada = d.lstSuperior20To30Km;
        } else if (selectedDistance === "30to40") {
            listaFiltrada = d.lstSuperior30To50Km;
        } else if (selectedDistance === "50mais") {
            listaFiltrada = d.lstSuperior50Km;
        }
        // quantidade acima
        if (listaFiltrada) {
            listaFiltrada = listaFiltrada.filter((supe) => ufsSelecionadas.includes(supe.nome.toUpperCase()));
            listaFiltrada.sort((a, b) => b.qtd - a.qtd);
            // console.log(listaFiltrada);
            
            for (let x in listaFiltrada) {
                const supe = listaFiltrada[x];
                superiorLabel.push(supe.nome);
                superiorData.push(supe.qtd);
                superiorQtd += supe.qtd;
            }
        }

        setPorcento({
            options: {
                ...porcento.options,
                plugins: {
                    ...porcento.options.plugins,
                    title: {
                        display: true,
                        text: 'Top 20 - % acima de 30 KM',
                    },
                },
                onClick: function (evt, elements, chart) {
                    const bars = chart.getElementsAtEventForMode(
                      evt,
                      'nearest',
                      { intersect: true },
                      true
                    );
                    if (bars.length === 0) return; // no bars
              
                    const bar = bars[0];
              
                    // Get index and label text
                    const index = bar.index;
                    const uf = chart.data.labels[index];

                    openDialogDetalhesGraficoPercentual(uf)
                  },
            },
            data: {
                ...porcento.data,
                labels: porcenLabel.slice(0, 20),
                datasets: [
                    {
                        ...porcento.data.datasets[0],
                        data: porcenData.slice(0, 20),
                        label: "% maior que 30km",
                    },
                ],
            },
        });

        setAcima30Km({
            options: {
                ...acima30km.options,
                plugins: {
                    ...acima30km.options.plugins,
                    title: {
                        display: true,
                        text: getDistanceTitle(selectedDistance),
                    },
                },
                onClick: function (evt, elements, chart) {
                    const bars = chart.getElementsAtEventForMode(
                      evt,
                      'nearest',
                      { intersect: true },
                      true
                    );
                    if (bars.length === 0) return; // no bars
              
                    const bar = bars[0];
              
                    // Get index and label text
                    const index = bar.index;
                    const uf = chart.data.labels[index];
                   
                    // let selectedDataset = chart.data.datasets[bar.datasetIndex];
                    // console.log('Selected label:', uf);
                    // console.log('Selected sub-label:', selectedDataset.label);
                    // console.log("Selected sub-label's value:", selectedDataset.data[index]);

                    openDialogDetalhesGrafico(uf)
                  },
            },
            data: {
                ...acima30km.data,
                labels: superiorLabel,
                datasets: [
                    {
                        ...acima30km.data.datasets[0],
                        data: superiorData,
                        label: 'Total',
                    },
                ],
            },
        });

        setTotalInscricoes({
            ...totalInscricoes,
            labels: getDistanceTitlePie(selectedDistance),
            datasets: [
                {
                    ...totalInscricoes.datasets[0],
                    data: [inferior30km,superio30km],
                    label: "Quantidade",
                },
            ],
        });
        // console.log(total)

        setInscricoes({
            options: {
                ...inscricoes.options,
                plugins: {
                    ...inscricoes.options.plugins,
                    title: {
                        display: true,
                        text: 'Top 20 - Total inscrições processadas',
                    },
                },
                onClick: function (evt, elements, chart) {
                    const bars = chart.getElementsAtEventForMode(
                      evt,
                      'nearest',
                      { intersect: true },
                      true
                    );
                    if (bars.length === 0) return; // no bars
              
                    const bar = bars[0];
              
                    // Get index and label text
                    const index = bar.index;
                    const uf = chart.data.labels[index];

                    openDialogDetalhesTotalIncricoes(uf)
                  },
            },
            data: {
                ...inscricoes.data,
                labels: cidadeLabel.slice(0, 20),
                datasets: [
                    {
                        ...inscricoes.data.datasets[0],
                        data: ufTotalProcessada.slice(0, 20),
                    },
                ],
            },
        });
    }

    const getDistanceTitle = (selectedDistance) => {
        switch (selectedDistance) {
            case "10to20":
                return 'Quantidade acima de 10 a 20 km';
            case "20to30":
                return 'Quantidade acima de 20 a 30 km';
            case "30to40":
                return 'Quantidade acima de 30 a 40 km';
            case "50mais":
                return 'Quantidade acima de 50 km';
            default:
                return 'Quantidade acima de 30 km';
        }
    }

    const getDistanceTitlePie = (selectedDistance) => {
        switch (selectedDistance) {
            case "10to20":
                return ['Inscrições Abaixo de 10 a 20 KM', 'Inscrições Acima de 10 a 20 KM'];
            case "20to30":
                return ['Inscrições Abaixo de 20 a 30 KM', 'Inscrições Acima de 20 a 30 KM'];
            case "30to40":
                return ['Inscrições Abaixo de 30 a 40 KM', 'Inscrições Acima de 30 a 40 KM'];
            case "50mais":
                return ['Inscrições Abaixo de 50 KM', 'Inscrições Acima de 50 KM'];
            default:
                return ['Inscrições Abaixo de 30 KM', 'Inscrições Acima de 30 KM'];
        }
    }


    const handleModalConfirm = (selectedValue) => {
        setSelectedDistance(selectedValue);
    
        setLoading(true);
        Service.GET('/api/localProva/graficoV2')
            .then(r => {
                setLoading(false);
                setDadoGrafico(r);
                if (r && r.status === 'CONCLUIDO') setShowGrafico(true);
                if (r && r.status === 'ATUALIZANDO') setShowGrafico(true);
                montaGraficos(r);
            }).catch(error => {
                console.error(error)
                setShowGrafico(false)
                
                setLoading(false)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao gerar Gráficos"
                })
            });
    };
    

    const handleModalConfirmFilter = (selectedValueUF) => {
        setSelectedFilter(selectedValueUF);
    };

    if (!showGrafico) {
        return (
            <Container fixed>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: '60vh' }}  // centers vertically
            >
                {dadoGrafico && (
                    <Grid item xs={12}>
                        {dadoGrafico.msg}
                    </Grid>
                )}
                <Grid item>
                    <CircularProgress size={50} sx={spinnerStyle} /> {/* Increased spinner size for visibility */}
                </Grid>
            </Grid>
        </Container>
        )
    }
    if (loading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '5vh', 
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    
    return (
        <Container fixed>
            {dadoGrafico && dadoGrafico.status === "EXECUTANDO" && <Alert severity="success">{dadoGrafico.msg}</Alert>}
            {openError &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity={mensagem.tipo}>
                        {mensagem.message}
                    </Alert>
                </Stack>
            }
            <Grid container spacing={4} mt={8}>
                <Box
                    sx={{
                        width: '100%',

                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginLeft: 3
                    }}
                >
                    <IconButton onClick={() => setOpenFilter(true)}>
                        <FilterAltIcon sx={{ color: 'black' }} />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mt={2} color={"#8a8a8a"}>
                        {selectedFilter.length > 0 ? (
                            <span> UFs selecionadas: {selectedFilter.join(', ').toUpperCase()}</span>
                        ) : (
                            <span> Nenhuma UF selecionada</span>
                        )}
                    </Typography>
                </Box>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <IconButton
                            onClick={() => setOpen(true)}
                            sx={{
                                position: 'absolute',
                                top: -5,
                                left: -5,
                            }}
                        >
                            <FilterAltIcon sx={{ color: 'black' }} />
                        </IconButton>
                        <Bar
                            options={acima30km.options}
                            data={acima30km.data}
                            style={{ marginTop: 15, width: '80%', cursor: 'pointer' }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar options={porcento.options} data={porcento.data}  style={{ cursor: 'pointer' }}/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar options={inscricoes.options} data={inscricoes.data} style={{ cursor: 'pointer' }}/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pie
                            data={totalInscricoes}
                            options={optionsPie}
                            style={{ width: '100%', height: '100%', padding: 20 }}
                        />
                    </Box>
                </Grid>
                <Grid container justifyContent="flex-end" mt={2} >
                    <Button variant="contained" onClick={ () => {
                            setOpenGerarRelatorio(true); 
                            setTipoRelatorio("PDF") 
                        } 
                        }
                    >
                        Gerar PDF
                    </Button>
                    <Button variant="contained"
                        onClick={ () => {
                            setOpenGerarRelatorio(true); 
                            setTipoRelatorio("CSV") 
                        } 
                        }
                    >
                        Gerar CSV
                    </Button>
                </Grid>
                <GenericModal validateModal={true} setOpen={setOpen} open={open} onConfirm={handleModalConfirm} selectedDistance={selectedDistance} setSelectedDistance={setSelectedDistance} />
                <FilterModal setOpen={setOpenFilter} open={openFilter} onConfirm={handleModalConfirmFilter} dados={dados} selectedFilter={selectedFilter} />
            </Grid>
            <GerarRelatorioDialog open={openGerarRelatorio} setOpen={setOpenGerarRelatorio} tipo={tipoRelatorio} />
            <div id='rederizarDialogDetalhesGrafico'/>
        </Container>

    )
}








