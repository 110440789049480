import { Alert, Autocomplete, Box, Button, Fade, Grid, IconButton, Modal, Paper, Popper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, TileLayer, Tooltip, useMap } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'
import L, { map } from "leaflet";
import FlagIcon from '@mui/icons-material/Flag';
import Service from "../services/Service";
import roomIconMapaVermelho from '../components/Imagens/roomIconMapaVermelho.png';
import roomIconMapaAzul from '../components/Imagens/roomIconMapaAzul.png';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const style2 = {
    position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#fff', // Using a white background for better contrast
        borderRadius: 8,
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Softer shadow for a modern look
        p: 4, // Increased padding
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto', // Automatic height based on content
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '16px',
};

const titleStyle = {
    fontWeight: '600', // Bold title
    marginBottom: '8px', // Space between title and spinner
    color: '#333', // Darker text for better readability
};

const spinnerStyle = {
    color: '#1976d2', // Custom spinner color
    marginTop: '16px', // Margin for separation
};

const improvedStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #e0e0e0',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

const improvedHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
    pb: 2,
    mb: 3,
};

const improvedFooterStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    mt: 3,
};

const contentStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    marginBottom: 15
};

const CustomPaper = (props) => (
    <Paper {...props} style={{ maxHeight: 200, overflowY: 'hidden'}} />
);
// const CustomPaper = (props) => {
//     const { key, ...otherProps } = props;
//     return (
//       <Paper key={key} {...otherProps} style={{ maxHeight: 200, overflowY: 'hidden' }} />
//     );
//   };

export default function TelaMapaInscricoes_v3() {
    const [listDestino, setListDestino] = useState([]);
    const [ufExistente, setUfExistente] = useState([]);
    const [ufSelecionada, setUfSelecionada] = useState(null);
    const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
    const [menuLateral, setMenuLateral] = useState(false)
    const [listOrigem, setListOrigem] = useState([]);
    const [listaPontoaPonto, setListaPontoaPonto] = useState([]);
    const [listaPontoaPontoFiltrada, setListaPontoaPontoFiltrada] = useState([]);
    const [listaConcatenada, setListaConcatenada] = useState([]);
    const [listaConcatenadaFiltrada, setListaConcatenadaFiltrada] = useState([]);
    const [listaConcatenadaProva, setListaConcatenadaProva] = useState([]);
    const [listaConcatenadaFiltradaProva, setListaConcatenadaFiltradaProva] = useState([]);
    const [openRegiao, setOpenRegiao] = React.useState(false);
    const [openMunicipio, setOpenMunicipio] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [cidades, setCidades] = useState([]);
    const [openDistancia, setOpenDistancia] = useState(false);
    const [distancia, setDistancia] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingMapa, setLoadingMapa] = useState(true);
    const [loadingCidade, setLoadingCidade] = useState(false);
    const [centerMapa, setCenterMapa] = useState([-15.7891, -47.8850]);
    const [zoom, setZoom] = useState(4);
    const [isforasteiro, setIsForasteiro] = useState(1);
    const [localProvaSelecionado, setLocalProvaSelecionado] = useState("");
    const [atualizarMapa, setAtualizarMapa] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [openCarregamentoMapa, setOpenCarregamentoMapa] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();
    const [mensagem, setMensagem] = useState({ tipo: "", message: "mensagem não anexada" })
    const [mensagemDialog, setMensagemDialog] = useState({ tipo: "", message: "mensagem não anexada" })
    const [openError, serOpenError] = useState(false)
    const [exibirErro, setExibirErro] = React.useState(false);

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        setOpenRegiao(false);
        setOpenMunicipio(false);
        setOpenDistancia(false);
    };
    
    const handleCloseModal = () => {
        setOpenModal(false)
    };
    
    const handleChangeUf = (value) => {
        setUfSelecionada(value);
        buscaCidades(value);
    };

    const handleChangeCidade = (value) => {
        setCidadeSelecionada(value);
        setLoadingMapa(false)
    };

    const buscaLocalProvaMapa = () => {
        if (ufSelecionada !== null && cidadeSelecionada !== null) {
            console.log(ufSelecionada, cidadeSelecionada);
            buscaLocalProva(); 
            setExibirErro(false);
        } else {
            setMensagemDialog({ tipo: "error", message: "Por favor, selecione a UF e o município desejado para prosseguir com a confirmação." })
            setExibirErro(true);
            setTimeout(() => {
                setExibirErro(false);
                // setMensagemDialog({ tipo: "", message: "mensagem não anexada" })
            }, 5000);
        }
    };
    
    useEffect(() => {
        if(localProvaSelecionado !== ""){
            recuperarLocalAlunos(localProvaSelecionado, isforasteiro)
        }

    }, [isforasteiro])

    useEffect(() => {
        buscarListUfDisponivel();
    }, [])

    const buscarListUfDisponivel = () => {
        Service.GET('/api/localProva/ufDisponivel')
            .then(r => {
                let ufs = r.map(item => item ?? item);
                setUfExistente(ufs);
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                // serOpenError(true)
                setMensagemDialog({
                    tipo: "error",
                    message: "Falha ao listar UFs."
                })
                setExibirErro(true);
                setTimeout(() => {
                    setExibirErro(false);
                    // setMensagemDialog({ tipo: "", message: "mensagem não anexada" })
                }, 5000);
            })
    }

    const concatenarList = () => {

        setListaConcatenada([
            ...listOrigem.map(item => ({ ...item, tipo: 'origem' })),
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])


        setListaConcatenadaFiltrada([
            ...listOrigem.map(item => ({ ...item, tipo: 'origem' })),
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])

    }

    const concatenarListLocalProva = () => {
        setListaConcatenadaProva([
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])


        setListaConcatenadaFiltradaProva([
            ...listDestino.map(item => ({ ...item, tipo: 'destino' }))
        ])
    }

    function buscarIcone(d) {
        let icone = roomIconMapaVermelho;
        if (d === 'origem') {
            icone = roomIconMapaVermelho;
        } else if (d === 'destino') {
            icone = roomIconMapaAzul;
        }

        let resp = L.icon({
            iconUrl: icone,
            iconAnchor: [14, 42],
            popupAnchor: [-1.5, -42]
        });
        return resp;
    }

    function buscaCidades(ufSelecionada) {
        setListDestino([]);
        setListOrigem([]);
        setCidadeSelecionada(null);
        setLoadingMapa(true);
        setListaPontoaPontoFiltrada([]);
        setListaConcatenadaFiltrada([]);
        setListaConcatenadaFiltradaProva([]);
        setLocalProvaSelecionado("");
        setLoadingCidade(true);
        Service.GET('/api/localProva/findByUF?uf=' + ufSelecionada)
            .then(r => {
                let arr = [];
                let arrSeleci = [];
                for (let x in r) {
                    arr.push({
                        nome: r[x].nome,
                        id: r[x].id.toString()
                    })
                    arrSeleci.push(r[x].id.toString());
                }
                setCidades(arr);
                setLoadingCidade(false);
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                // serOpenError(true)
                setMensagemDialog({
                    tipo: "error",
                    message: "Falha ao listar cidades."
                })
                setExibirErro(true);
                setTimeout(() => {
                    setExibirErro(false);
                    // setMensagemDialog({ tipo: "", message: "mensagem não anexada" })
                }, 5000);
            })
    }

    function buscaLocalProva() {
        setListDestino([]);
        setListOrigem([]);
        setListaConcatenadaFiltrada([]);
        setListaConcatenadaFiltradaProva([]);
        setListaPontoaPontoFiltrada([]);
        setOpen(false);
        setLocalProvaSelecionado("");
        setLoadingMapa(true);
        Service.GET('/api/localProva/recuperarMapaProvaPorMunicipio?co_municipio=' + cidadeSelecionada.id)
            .then(r => {
                for (let x in r) {
                    listDestino.push({
                        coordenada: r[x]?.coorD,
                        local: r[x]?.uf,
                        co_municipio: r[x]?.municipio.toString(),
                        total_inscricoes: r[x]?.totalInscricoes.toString(),
                        local: r[x]?.localProva
                    });
                }

                setZoom(10);
                setCenterMapa([r[0]?.coorD.latitude, r[0]?.coorD.longitude]);
                setAtualizarMapa(true);
                concatenarListLocalProva();
                // setLoadingMapa(false);
                setOpenModal(false)
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao buscar local de prova."
                })
                setOpenModal(false)
                setTimeout(() => {
                    serOpenError(false);
                    // setMensagem({ tipo: "", message: "mensagem não anexada" })
                }, 5000);
                
            });
    }

    function AtualizarCentroZoom({ center, zoom }) {
        const map = useMap(); // Get the map instance

        useEffect(() => {
            // Default coordinates if center is undefined
            if (center[0] == undefined || center[1] == undefined) {
                // Default coordinates for center
                const defaultCenter = [-15.7891, -47.8850];
                map.setView(defaultCenter, 4); // Use default center
            } else if(atualizarMapa){
                // Update the map view if the center and zoom are valid
                map.setView(center, zoom);
            }

            // Resetting the update flag
            if (atualizarMapa) {
                setAtualizarMapa(false);
            }
        }, [atualizarMapa]); // Dependencies array

        return null; // No rendering needed
    }

    function recuperarLocalAlunos(localProva, forasteiro) {
        setListDestino([]);
        setListOrigem([]);
        setListaPontoaPontoFiltrada([]);
        setOpenCarregamentoMapa(true);
        setLocalProvaSelecionado(localProva);

        Service.GET('/api/localProva/recuperarLocalAlunos?latitude=' + localProva.coordenada.latitude + '&longitude=' + localProva.coordenada.longitude + "&forasteiro="+forasteiro)
            .then(r => {
                let listaPontoaPontoAuxiliar = [];
                for (let x in r) {
                    const existeOrigem = listOrigem.some(coord => coord.coordenada.latitude === r[x]?.coorO.latitude && coord.coordenada.longitude === r[x]?.coorO.longitude)

                    const exitePontoPonto = listaPontoaPontoAuxiliar.some(item =>
                        item.linha[0][0] === r[x]?.coorD.latitude &&
                        item.linha[0][1] === r[x]?.coorD.longitude &&
                        item.linha[1][0] === r[x]?.coorO.latitude &&
                        item.linha[1][1] === r[x]?.coorO.longitude)

                    if (!existeOrigem) {listOrigem.push({
                        coordenada: r[x]?.coorO,
                        local: r[x]?.sg_uf,
                        co_municipio: r[x]?.co_municipio.toString()
                    })}

                    if (!exitePontoPonto) {
                        let coordenada = [
                            [r[x]?.coorD.latitude, r[x]?.coorD.longitude],
                            [r[x]?.coorO.latitude, r[x]?.coorO.longitude]
                        ]

                        listaPontoaPontoAuxiliar.push({
                            linha: coordenada,
                            distancia: calculateDistance(coordenada)
                        })
                    }
                }

                setListaPontoaPonto(listaPontoaPontoAuxiliar);
                concatenarList();
                setOpenCarregamentoMapa(false);
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao buscar local do aluno"
                })
            });
    }

    useEffect(() => {
        setDistancia(50)
        if(distancia > 0) {
            let arrListaSele = listaPontoaPonto.filter(item => item.distancia <= distancia)
            setListaPontoaPontoFiltrada(arrListaSele)
        }
                        
    }, [listaPontoaPonto]);

    function calculateDistance(coord) {
        const R = 6371; // Raio da Terra em Km
        const dLat = (coord[1][0] - coord[0][0]) * Math.PI / 180; // Diferença de latitude em radianos
        const dLon = (coord[1][1] - coord[0][1]) * Math.PI / 180; // Diferença de longitude em radianos
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(coord[0][0] * Math.PI / 180) * Math.cos(coord[1][0] * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distância em quilômetros
        // console.log("dis:",distance ," ->", Math.ceil(distance));
        return Math.ceil(distance);
    }

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '5vh',
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    



    return (
        <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            {openError &&
                <Stack sx={{ width: '100%' }} spacing={2} mb={2} ml={2} mr={2} >
                    <Alert severity={mensagem.tipo}>
                        {mensagem.message}
                    </Alert>
                </Stack>
            }
                <Grid item xs={menuLateral ? 11 : 12} sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 1500,
                            height: 750,
                            marginBottom: 16,
                            border: "3px solid black",
                            position: "relative",
                        }}
                    >
                        <MapContainer
                            center={centerMapa}
                            doubleClickZoom={false}
                            id="mapId"
                            zoom={zoom}
                            style={{ width: "100%", height: "100%" }} // O mapa ocupa todo o Box
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <MarkerClusterGroup chunkedLoading>
                                {listaConcatenadaFiltradaProva && listaConcatenadaFiltradaProva.map((coord, index) => (
                                    <Marker key={index} position={[coord?.coordenada.latitude, coord?.coordenada.longitude]} icon={buscarIcone(coord?.tipo)} 
                                        eventHandlers={{
                                        click: () => {
                                            recuperarLocalAlunos(coord, isforasteiro)
                                        }
                                      }}>
                                        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                                            <p>Local: {coord?.local}</p>
                                            <p>Total Incrições: {coord?.total_inscricoes}</p>
                                            <p>Latitude: {coord?.coordenada.latitude}</p>
                                            <p>Longitude: {coord?.coordenada.longitude}</p>
                                        </Tooltip>
                                      </Marker>
                                ))}
                                </MarkerClusterGroup>

                            <MarkerClusterGroup>
                                {listaConcatenadaFiltrada && listaConcatenadaFiltrada.map((coord, index) => (
                                    <Marker key={index} position={[coord?.coordenada.latitude, coord?.coordenada.longitude]} icon={buscarIcone(coord?.tipo)}>
                                        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                                            <p>UF: {coord?.local}</p>
                                            <p>Latitude: {coord?.coordenada.latitude}</p>
                                            <p>Longitude: {coord?.coordenada.longitude}</p>
                                        </Tooltip>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>

                            {listaPontoaPontoFiltrada && listaPontoaPontoFiltrada.map((coord, index) => (
                                <Polyline key={index + "_xpto"} positions={coord.linha} color={'#6FA1EC'} />
                            ))}

                            <IconButton aria-label="filter" onClick={() => setOpenModal(true)} sx={{
                                position: 'absolute',
                                top: 76,
                                left: -2,
                                zIndex: 1000,
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}>
                                <FilterAltIcon sx={{ color: '#1B3058' }} />
                            </IconButton>
                            {localProvaSelecionado != "" &&
                                <div>
                                    <IconButton aria-label="filter" onClick={handleClick('right')} sx={{
                                        position: 'absolute',
                                        top: 126,
                                        left: -2,
                                        zIndex: 1000,
                                        backgroundColor: '#fff',
                                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                        },
                                    }}>
                                        <FlagIcon sx={{ color: '#1B3058' }} />
                                    </IconButton>

                                    <Popper
                                        sx={{ zIndex: 1200, borderRadius: 3 }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        placement={placement}
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} >
                                                <Paper sx={{ borderRadius: 3, marginLeft: 1 }}>
                                                    {/* <Typography sx={{ p: 2 }}>The content of the Popper.</Typography> */}
                                                    <Box sx={{ display: 'flex', display: 'grid', p: 2 }}>
                                                        <Button variant="contained" onClick={() => setIsForasteiro(1)} sx={{
                                                            backgroundColor: isforasteiro == 1 ? '#CE3A32' : '#F4F6F8',
                                                            color: 'black',
                                                            '&:hover': {
                                                                backgroundColor: '#CE3A32',
                                                            }
                                                        }} >Sem Forasteiro</Button>
                                                        <Button variant="contained" onClick={() => setIsForasteiro(2)} sx={{
                                                            backgroundColor: isforasteiro == 2 ? '#CE3A32' : '#F4F6F8',
                                                            color: 'black',
                                                            '&:hover': {
                                                                backgroundColor: '#CE3A32',
                                                            }
                                                        }} >Com Forasteiro</Button>
                                                        <Button variant="contained" onClick={() => setIsForasteiro(3)} sx={{
                                                            backgroundColor: isforasteiro == 3 ? '#CE3A32' : '#F4F6F8',
                                                            color: 'black',
                                                            '&:hover': {
                                                                backgroundColor: '#CE3A32',
                                                            }
                                                        }} >Somente Forasteiro</Button>
                                                    </Box>
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            }
                            <AtualizarCentroZoom center={centerMapa} zoom={zoom} />
                        </MapContainer>
                    </Box>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={improvedStyle}>
                        {exibirErro && (!ufSelecionada || !cidadeSelecionada) && (
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity={mensagemDialog.tipo}>
                                    {mensagemDialog.message}
                                    
                                </Alert>
                            </Stack>
                        )}


                            <Box sx={improvedHeaderStyle}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Filtrar Mapa de Inscrições
                                </Typography>
                                <IconButton aria-label="close" onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Box sx={contentStyle}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            id="combo-box-uf"
                                            PaperComponent={CustomPaper}
                                            options={ufExistente}
                                            getOptionLabel={(option) => option}
                                            onChange={(event, newValue) => handleChangeUf(newValue)}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option}>{option}</li>
                                              )}
                                            renderInput={(params) => <TextField {...params} 
                                            label="UF*" variant="outlined" />}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            value={ufSelecionada }
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={6}>
                                        {loadingCidade ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%',alignItems: 'center' }}>
                                                <CircularProgress size={24}/>
                                            </Box>
                                        ) : (
                                            cidades.length > 0 && (
                                                <Autocomplete
                                                id="combo-box-cidades"
                                                PaperComponent={CustomPaper}
                                                options={cidades || []}
                                                getOptionLabel={(option) => option?.nome || ''}
                                                onChange={(event, newValue) => handleChangeCidade(newValue)}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>{option.nome}</li>
                                                  )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Cidade*"
                                                        variant="outlined"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                value={cidadeSelecionada || null}
                                                />
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={improvedFooterStyle}>
                                <Button variant="contained" color="primary" onClick={buscaLocalProvaMapa}>
                                    Confirmar
                                </Button>
                                <Button variant="contained" color="error" onClick={handleCloseModal}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={openCarregamentoMapa}
                        // onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style2}>
                            <Box sx={headerStyle}>
                                <Typography id="loading-modal-title" variant="h6" component="h2" sx={titleStyle}>
                                    Carregando mapa...
                                </Typography>
                            </Box>
                            <CircularProgress size={50} sx={spinnerStyle} /> {/* Increased spinner size for visibility */}
                            <Typography variant="body2" sx={{ marginTop: 2, color: '#666' }}>
                                Isso pode levar alguns instantes. Por favor, aguarde.
                            </Typography>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>


        </React.Fragment >
    );



}