import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmDialog(props) {
  const { open, setOpen, title, btnLabel, btn2Label, actionOnClick } = props;
  //   const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (actionOnClick) actionOnClick();
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={2}>
            <WarningAmberIcon fontSize="large" color="secondary" />
            <div>
              {title}
            </div>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          {props.children}

        </DialogContent>
        <DialogActions >
          <Button autoFocus onClick={handleClose}>
            {btn2Label ? btn2Label : "Cancelar"}
          </Button>
          <Button autoFocus onClick={handleClick}>
            {btnLabel ? btnLabel : "Fechar"}
          </Button>

        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
