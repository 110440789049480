import React from "react";
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, Typography, Breadcrumbs, Stack, Alert } from "@mui/material";
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CircularProgress from '@mui/material/CircularProgress';
import Service from "../../services/Service";
import { Link, useNavigate, useParams } from 'react-router-dom';

const headCells = [
    {
        id: 'localProva',
        label: 'Escola',
    },
    {
        id: 'totalInscricoes',
        label: 'Total Inscrições',
    },
    {
        id: 'totalProcessado',
        label: 'Total Processada',
    },
    {
        id: 'distanciaMedia',
        label: 'Distância Média',
    },
    {
        id: 'abaixo8km',
        label: '% Abaixo de 8 km',
    },
    {
        id: 'entre15e30km',
        label: '% Acima de 15km',
    },
    {
        id: 'acima30km',
        label: '% Acima de 30km',
    },
    {
        id: 'qtdGoogleProva',
        label: '% Google',
    },
    {
        id: 'qtdIBGEProva',
        label: '% Ibge/Local',
    },
    {
        id: 'qtdGoogleAluno',
        label: '% Google',
    },
    {
        id: 'qtdIBGEAluno',
        label: '% Ibge/Local',
    }
];

export default function ConsolidadaLocalProvaTable() {
    const params = useParams();
    const [lista, setLista] = React.useState();
    const [wait, setWait] = React.useState(false);
    const [paginacao, setPaginacao] = React.useState();
    const [orderBy, setOrderBy] = React.useState(headCells[0].id);
    const [order, setOrder] = React.useState('asc');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [mensagem, setMensagem] = React.useState({ tipo: "", message: "mensagem não anexada" })
    const [openError, serOpenError] = React.useState(false)

    const navigate = useNavigate();

    React.useEffect(() => {
        buscaDados();
    }, [order, orderBy, rowsPerPage, page]);

    function handleClick(event, link) {
        event.preventDefault();        
        if (link === "1") {
            navigate("/consolidado");
        } else if (link === "2") {
            navigate(-1);
        }
    }
    const breadcrumbs = [

        <Link underline="hover" key="1" color="inherit" href="/" onClick={(e) => handleClick(e, "1")}>
            Consolidado
        </Link>,
        <Link underline="hover" key="1" color="inherit" href="/" onClick={(e) => handleClick(e, "2")}>
            Consolidado Município
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            Consolidado Local Prova
        </Typography>,
    ];

    function buscaDados() {
        const dado = { ...paginacao }
        dado.mun = params.mun;
        dado.orderBy = orderBy;
        dado.order = order;
        dado.rowsPerPage = rowsPerPage;
        dado.page = page;

        Service.POST("/api/consolidado/prova", dado)
            .then(r => {
                setLista(r.list);
                setTotal(r.total)
                setWait(false);
            }).catch(error => {
                console.error(error)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao listar tabela consolidado local prova."
                })
                setWait(false);
            })
    }

    const createSortHandler = (property) => (event) => {
        //onRequestSort(event, property);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlerMkM = (r) => {
        if (r >= 1000) {
            return (r / 1000).toFixed(2) + " Km";
        } else {
            return r + " M";
        }
    }
    function isValid(value) {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    }

    // function calcFaltantes(res) {
    //     if (!isValid(res.totalProcessado) || !isValid(res.totalInscricoes)) return '0%';
    //     return (100 - (res.totalProcessado * 100 / res.totalInscricoes)).toFixed(2) + "%";
    // }

    function per8km(res) {
        
        if (!isValid(res.totalProcessadoSemForasteiro) || !isValid(res.abaixo8km) || !isValid(res.totalProcessadoSemForasteiro)) return '0%';
        return ((res.abaixo8km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function per8e15km(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.entre15e30km) || !isValid(res.totalProcessadoSemForasteiro)) return '0%';
        return ((res.entre15e30km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function perAc15km(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.acima30km) || !isValid(res.totalProcessadoSemForasteiro)) return '0%';
        return ((res.acima30km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function provaGoogle(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.qtdGoogleProva)) return '0%';
        return ((res.qtdGoogleProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function provaIBGE(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.qtdIBGEProva)) return '0%';
        return ((res.qtdIBGEProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function alunoGoogle(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.qtdGoogleAluno)) return '0%';
        return ((res.qtdGoogleAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function alunoIBGE(res) {
        if (!isValid(res.totalProcessado) || !isValid(res.qtdIBGEAluno)) return '0%';
        return ((res.qtdIBGEAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function formataNumero(number) {
        if (isNaN(number)) return "0";
        return number.toLocaleString("pt-BR");
    }

    return (
        <Container maxWidth="xl">
            <h1 style={{ textAlign: 'center' }}>Tabela Consolidado Local Prova</h1>
            {openError &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity={mensagem.tipo}>
                        {mensagem.message}
                    </Alert>
                </Stack>
            }
            <Stack spacing={2} mb={2} ml={1}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={"medium"}>
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        {/* Título geral da tabela */}
                        <TableRow>
                            <TableCell colSpan={3} style={{ borderRight: "1px solid white", color: "red", padding: "12px", textAlign: "center" }}>
                            </TableCell>
                            <TableCell colSpan={4} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Cálculos realizados desconsiderando os forasteiros
                            </TableCell>
                            <TableCell colSpan={2} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Prova)
                            </TableCell>
                            <TableCell colSpan={2} style={{ color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Aluno)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            {headCells.map((headCell, index) => {
                                if (headCell.notOrder) {
                                    return (
                                        <TableCell key={index} style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }}>
                                            {headCell.label}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell key={index} style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }}
                                            sortDirection={orderBy === headCell.id ? order : false}
                                        >
                                            <TableSortLabel
                                                active={order === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>)
                                }
                            }
                            )}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lista?.map((resultado, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.localProva}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{formataNumero(resultado.totalInscricoes)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{formataNumero(resultado.totalProcessado)}</TableCell>
                                {/* nao forasteiro */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{handlerMkM(resultado.distanciaMedia)}</TableCell>
                                {/* <TableCell style={{ borderRight: "1px solid lightgray" }}>{calcFaltantes(resultado)}</TableCell> */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8km(resultado)}</TableCell>

                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8e15km(resultado.entre15e30km)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{perAc15km(resultado)}</TableCell>
                                {/* prova */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaIBGE(resultado)}</TableCell>
                                {/* aluno  */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoIBGE(resultado)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {lista &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Linhas por Página"}
                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}