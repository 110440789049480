import React, { useEffect } from "react";
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Service from "../services/Service";

let intervalId = 0;

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

export default function ListaArquivos() {
    const [processo, setProcesso] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [filtro, setFiltro] = React.useState();
    const [open, setOpen] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        const filtro = {
            page: 0,
            rowsPerPage: rowsPerPage,
        }

        buscaprocesso(filtro);



    }, [])

    const buscaPorcentagem = (lst) => {
        if (!lst) return
        let pro = '';
        for (let x in lst) {
            if (pro.length > 1) pro += ","
            pro += lst[x].processo
        }

        Service.GET('/api/processo/porcentagem?processos=' + pro)
            .then(r => {
                for (let j in r) {
                    for (let x in lst) {
                        if (j === lst[x].processo) {
                            lst[x].porcentagem = r[j];
                            break;
                        }
                    }
                }
                setProcesso(lst);
            });
    }

    const buscaprocesso = (dado) => {
        Service.POST('/api/processo/list', dado)
            .then(r => {
                setTotal(r.total)
                // setProcesso(r.lst);

                clearInterval(intervalId);
                buscaPorcentagem(r.lst);

                intervalId = setInterval(() => {
                    buscaPorcentagem(r.lst);
                }, 10000);

            });


    }

    const downloadCsv = (row) => {
        const dado = {
            processo: row.processo
        }
        setOpen(true);
        fetch('/api/processo/downloadCsv',
            {
                method: "POST",
                body: JSON.stringify(dado),
                headers: {
                    'Content-Type': 'application/json',
                    // Accept: 'application/json',
                    // Authorization: `Bearer ${localStorage.accessToken}`
                }
            })
            .then(res => {
                // download(res.text());
                return res.text();
            })
            .then(txt => {
                setOpen(false);
                download(txt, row.processo + ".csv");
            }
            );

    }

    const download = function (data, nome) {

        // Creating a Blob for having a csv file format  
        // and passing the data with type 
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url 
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML 
        const a = document.createElement('a')

        // Passing the blob downloading url  
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading 
        // and passing the download file name 
        a.setAttribute('download', nome);

        // Performing a download with click 
        a.click()
    }

    const montaLineColor = (row) => {
        if (!row.concluido) return "#76B5E5";
        if (row.rotas == null) return "#DADADA";
        return null;
    }
    const handleChangePage = (event, newPage) => {
        const dado = {
            page: newPage,
            rowsPerPage: rowsPerPage,
            filter: filtro
        }
        buscaprocesso(dado);

        setProcesso(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setProcesso(0);
    };
    const formataData = (dt) => {
        if (!dt) return '';
        const result2 = new Date(dt).toLocaleString('en-GB', {
            hour12: false,
        });
        return result2;
    }
    return (
        <Grid>
            <Grid sx={{

                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 2,
                marginTop: 1

            }}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Processo</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Pendente</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processo && processo.map((row, index) => (
                                <TableRow sx={{ backgroundColor: montaLineColor(row) }}
                                    key={index}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.processo}</TableCell>
                                    <TableCell>{formataData(row.dtCriacao)}</TableCell>
                                    <TableCell>{row.porcentagem.toFixed(0)}%</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="download" onClick={() => downloadCsv(row)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25
                                        // ,{ label: 'All', value: -1 }
                                    ]}
                                    colSpan={3}
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    labelRowsPerPage={"Linhas por Página"}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'registro por pagina',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>
                </TableContainer>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={()=> setOpen(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}