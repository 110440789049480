import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Checkbox, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import RoutineMachine from './RoutineMachine'
import CloseIcon from '@mui/icons-material/Close';
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import Service from '../services/Service';
import CircularProgress from '@mui/material/CircularProgress';


export default function RotaInscricao(props) {
    const [open, setOpen] = React.useState(props.open);
    const { inscricao, coorO, coorD, uf, municipio_prova, distancia } = props;
    const [exibirRota, setExibirRota] = React.useState(true);
    const [exibirPontoAPonto, setExibirPontoAPonto] = React.useState(true);
    const [nomeParticipante, setNomeParticipante] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [mensagem, setMensagem] = React.useState({ tipo: "", message: "mensagem não anexada" })
    const [openError, serOpenError] = React.useState(false)

    const pos = [
        [coorO.latitude, coorO.longitude],
        [coorD.latitude, coorD.longitude],
    ];

    React.useEffect(() => {
        // console.log(distancia)
        setLoading(true)
        Service.GET('/api/participante/findByParticipante?inscricao=' + inscricao)
            .then(r => {
                setLoading(false)
                setNomeParticipante(r.nomeParticipante)
            }).catch(error => {
                console.error(error)
                
                setLoading(false)
                serOpenError(true)
                setMensagem({
                    tipo: "error",
                    message: "Falha ao gerar rota"
                })
            });
    }, [inscricao, nomeParticipante])

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeExibirRota = (e) => {
        setExibirRota(e.target.checked);
    }

    const handleChangeExibirPontoAPonto = (e) => {
        setExibirPontoAPonto(e.target.checked);
    }

    let DefaultIcon = L.icon({
        iconUrl: icon,
        iconAnchor: [14, 42],
        popupAnchor: [-1.5, -42]

    });
    L.Marker.prototype.options.icon = DefaultIcon;
    return (
        <React.Fragment>
            <Dialog id="Teste"
                // fullWidth={true}
                maxWidth={"max-width"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Typography style={{ textAlign: "center", fontSize: 26, marginTop: 0 }}>Rota Detalhada</Typography>
                    {openError &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity={mensagem.tipo}>
                                {mensagem.message}
                            </Alert>
                        </Stack>
                    }
                    <Grid container >
                        <Grid item xs={8}>
                            <Grid container spacing={1} >
                                <Grid item>
                                    <span style={{ fontWeight: 'bold' }}>Inscrição nº: </span>
                                </Grid>
                                <Grid item>
                                    {inscricao}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1} style={{ justifyContent: 'end' }}>
                                <Grid item>
                                    <FormControlLabel style={{ padding: '0px 9px' }}
                                        value="end"
                                        control={<Checkbox style={{ padding: '0 9px' }} onChange={handleChangeExibirRota} checked={exibirRota} />}
                                        label="Exibir rota"
                                        labelPlacement="start"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={1} >
                                <Grid item>
                                    <span style={{ fontWeight: 'bold' }}>UF: </span>
                                </Grid>
                                <Grid item>
                                    {uf}
                                </Grid>
                                <Grid item>
                                    <span style={{ fontWeight: 'bold' }}>Cidade:</span>
                                </Grid>
                                <Grid item>
                                    {municipio_prova}
                                </Grid>
                                <Grid item>
                                    <span style={{ fontWeight: 'bold' }}>Distância:</span>
                                </Grid>
                                <Grid item>
                                    {distancia}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={4}>
                            <Grid container spacing={1} style={{ justifyContent: 'end' }}>
                                <Grid item>
                                    <FormControlLabel style={{ padding: '0px 9px' }}
                                        value="end"
                                        control={<Checkbox style={{ padding: '0 9px' }} onChange={handleChangeExibirPontoAPonto} checked={exibirPontoAPonto} />}
                                        label="Exibir ponto a ponto"
                                        labelPlacement="start"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon style={{ color: '#1864c5' }} />
                </IconButton>
                <DialogContent>
                    <Box
                        sx={{
                            width: 1,
                            height: '50vh',
                            width: '99%',
                            marginBottom: 2,
                            border: "3px solid black",
                            position: "relative",
                            overflow: 'hidden',

                        }}
                    >


                        <MapContainer
                            center={[coorD.latitude, coorD.longitude]}
                            doubleClickZoom={false}
                            id="mapId"
                            zoom={10}
                            style={{ width: "100%", height: "100%" }} 
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />

                            <Marker position={[coorD.latitude, coorD.longitude]} />
                            <Marker position={[coorO.latitude, coorO.longitude]} />
                            {exibirRota && <RoutineMachine coorO={coorO} coorD={coorD} />}
                            {exibirPontoAPonto && <Polyline positions={pos} color={'#6FA1EC'} />}

                        </MapContainer>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}