import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Service from '../../services/Service';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';



export default function DialogDetalhesTotalIncricoes(props) {
    const [open, setOpen] = React.useState(props.open);
    const { uf, distancia } = props;
    const [descricaoDialog, setDescricaoDialog] = React.useState("");
    const [lista, setLista] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [ordem, setOrdem] = React.useState({
        campo: '',
        ordem: 'asc'
    });
    const dado = {
        uf: uf,
        distancia: distancia,
        page: 0,
        rowsPerPage: rowsPerPage,
        ordem: ordem
    }

    React.useEffect(() => {
        buscaLocalProva(dado);
    }, [descricaoDialog, rowsPerPage, ordem])

    const handleClose = () => {
        setOpen(false);
    };

    function buscaLocalProva(dado) {
        Service.POST('/api/localProva/detalhamentoGraficoIncricoes', dado)
            .then(r => {
                // console.log(r)
                setTotal(r.count)
                setLista(r.lista);
            });
    }

    const montaLineColor = (row) => {
        return null;
    }

    const handleChangePage = (event, newPage) => {
        const dado = {
            uf: uf,
            distancia: distancia,
            page: newPage,
            rowsPerPage: rowsPerPage,
            ordem: ordem
        }
        buscaLocalProva(dado);

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const dado = {
            uf: uf,
            distancia: distancia,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
            ordem: ordem
        }
        buscaLocalProva(dado);
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth={"max-width"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Typography style={{ textAlign: "center", margin: '5px', fontSize: 24 }}>Percentual por município</Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon style={{ color: '#1864c5' }} />
                </IconButton>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table" size={'small'}>
                            <TableHead style={{ backgroundColor: "#044785" }}>
                                <TableRow >
                                    <TableCell sortDirection={false} style={{ color: "white", padding: '12px' }}>
                                        UF
                                    </TableCell>
                                    <TableCell sortDirection={false} style={{ color: "white" }}>
                                        Município
                                    </TableCell>
                                    <TableCell sortDirection={false} style={{ color: "white" }}>
                                        Quantidade
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lista && lista.map((row, index) => (
                                    <TableRow sx={{ backgroundColor: montaLineColor(row) }}
                                        key={index}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{uf}</TableCell>
                                        <TableCell>{row.municipio}</TableCell>
                                        <TableCell>{row.quantidade}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>


                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25
                                            // ,{ label: 'All', value: -1 }
                                        ]}
                                        colSpan={3}
                                        count={total}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage={"Linhas por Página"}
                                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'registro por pagina',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}