import React, { useEffect, useRef, useState } from 'react';
// import { MapContainer } from 'react-leaflet/MapContainer'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import { useMap } from 'react-leaflet/hooks'
import Service from '../services/Service';

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconAnchor: [19, 46],
    popupAnchor: [-6, -47]

});
function Mapa() {
    const inputEl = useRef(null);

    const [devices, setDevices] = useState(null);
    const [coord, setCoord] = useState(null);

    useEffect(() => {
        buscaDispositivos();
    }, []);

    function buscaDispositivos() {
        // console.log(">>>>");
        Service.GET('/api/localidade/findAll')
            .then(r => {
                setDevices(r);
                let log = 0;
                let lat = 0;
                for (let x in r) {
                    let dis = r[x];
                    log += dis.longitude;
                    lat += dis.latitude;
                }
                if (log !== 0) {
                    setCoord([lat / r.length, log / r.length])
                }

            });
    }
    return (
        <div>
            <h2>Um Teste</h2>
            {devices &&
                <MapContainer
                    ref={inputEl} style={{
                        height: '60vh',
                        position: 'center',
                        borderRadius: '15px',
                    }}
                    center={[coord[0], coord[1]]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                        <Marker position={[-15.7926569,-47.9276539]} icon={DefaultIcon}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>

                    {devices.map((disp, index) => (
                        <Marker key={index} position={[disp.latitude, disp.longitude]} icon={DefaultIcon}>
                            <Popup>
                                Dispositivo { disp.nome}
                            </Popup>
                        </Marker>
                    ))}

                </MapContainer>}
        </div>
    )
}

export default Mapa;