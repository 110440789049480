import { Box, Button, Container, Grid, } from "@mui/material";

import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import Service from "../services/Service";
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend

);

const options = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

const optionsPie = {
    responsive: true,
    maintainAspectRatio: false
}

const data = {
    labels: ["AaaA", "BbbB"],
    datasets: [
        {
            label: 'Dataset 1',
            data: [12, 30],
            borderColor: 'rgba(1, 102, 255, 0.2)',
            backgroundColor: 'rgba(1, 102, 255, 1)',
        }
    ],
};

const dataPie = {
    labels: ['Inscrições Abaixo de 30 KM', 'Inscrições Acima de 30 KM',],
    datasets: [
        {
            label: '# of Votes',
            data: [1219267, 50089],
            backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export default function GraficosPage() {
    const [inscricoes, setInscricoes] = useState({ options: options, data: data });
    const [porcento, setPorcento] = useState({ options: options, data: data });
    const [qtd, setQtd] = useState({ options: options, data: data });
    // const [selecao, setSelecao] = useState();
    // const [listUf, setListUf] = useState([]);
    const [totalInscricoes, setTotalInscricoes] = useState(dataPie);

    useEffect(() => {

        Service.GET('/api/localProva/grafico')
            .then(r => {
                // console.log("resposta", r);
                // let arryUf = ["Todos"];//r.listUf;
                // arryUf.push(...r.listUf);
                // setListUf(arryUf);
                montaGraficos(r);
            });
    }, [])

    function montaGraficos(d) {
        let cidadeLabel = [];
        let cidadeData = [];
        let superiorLabel = [];
        let superiorData = [];
        let porcenLabel = [];
        let porcenData = [];
        let total = 0;
        let superiorQtd = 0;
        let arryPorcento = [];
        if (d.superiorKm && d.superiorKm.length > 0) {
            for (let x in d.superiorKm) {
                const supe = d.superiorKm[x];
                const insc = d.inscritoMunicipio[x];
                if (supe.nome === insc.nome) {
                    arryPorcento.push({ nome: supe.nome, qtd: ((supe.qtd / insc.qtd) * 100).toFixed(2) })
                } else {
                    // console.log(">>>>>>>>>>>>Error")
                }
            }
        }
        if (arryPorcento && arryPorcento.length > 0) {
            arryPorcento.sort((a, b) => b.qtd - a.qtd);
            for (let x in arryPorcento) {
                const porcento = arryPorcento[x];
                porcenLabel.push(porcento.nome);
                porcenData.push(porcento.qtd);
            }
        }
        if (d.inscritoMunicipio && d.inscritoMunicipio.length > 0) {
            d.inscritoMunicipio.sort((a, b) => b.qtd - a.qtd);
            for (let x in d.inscritoMunicipio) {
                const insc = d.inscritoMunicipio[x];
                cidadeLabel.push(insc.nome);
                cidadeData.push(insc.qtd);
                total += insc.qtd
            }
        }
        if (d.superiorKm && d.superiorKm.length > 0) {
            d.superiorKm.sort((a, b) => b.qtd - a.qtd);
            for (let x in d.superiorKm) {
                const supe = d.superiorKm[x];
                // const insc = d.inscritoMunicipio[x];
                superiorLabel.push(supe.nome);
                superiorData.push(supe.qtd);
                superiorQtd += supe.qtd
            }
        }

        setPorcento({
            options: {
                ...porcento.options,
                plugins: {
                    ...porcento.options.plugins,
                    title: {
                        display: true,
                        text: 'Top 20 - % acima de 30 KM',
                    },
                }
            },
            data: {
                ...porcento.data,
                labels: porcenLabel,
                datasets: [
                    {
                        ...porcento.data.datasets[0],
                        data: porcenData,
                        label: porcenLabel,
                    }
                ],
            }
        });
        setQtd({
            options: {
                ...qtd.options,
                plugins: {
                    ...qtd.options.plugins,
                    title: {
                        display: true,
                        text: 'Top 20 - Quantidade acima de 30 km',
                    },
                }
            },
            data: {
                ...qtd.data,
                labels: superiorLabel,
                datasets: [
                    {
                        ...qtd.data.datasets[0],
                        data: superiorData,
                    }
                ],
            }
        });

        setTotalInscricoes({
            ...totalInscricoes,
            datasets: [{
                ...totalInscricoes.datasets[0],
                //['Inscrições Abaixo de 30 KM', 'Inscrições Acima de 30 KM',],
                data: [total - superiorQtd, superiorQtd]
            }]
        });

        setInscricoes({
            options: {
                ...inscricoes.options,
                plugins: {
                    ...qtd.options.plugins,
                    title: {
                        display: true,
                        text: 'Top 20 - Total inscrições processadas',
                    },
                }
            },
            data: {
                ...inscricoes.data,
                labels: cidadeLabel,
                dataset: [
                    {
                        ...inscricoes.data.datasets[0],
                        data: cidadeData,
                    }
                ],
            }
        });
    }
    return (
        <Container fixed >
            <Grid container spacing={4} mt={8}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar
                            options={inscricoes.options}
                            data={inscricoes.data}
                            style={{ width: '100%', height: '100%', padding: 20 }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar options={qtd.options} data={qtd.data} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar options={porcento.options} data={porcento.data} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 350,
                            border: '2px solid black',
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pie
                            data={totalInscricoes}
                            options={optionsPie}
                            style={{ width: '100%', height: '100%', padding: 20 }}
                        />
                        
                    </Box>
                </Grid>

                <Grid container justifyContent="flex-end" mt={2} >
                    <Button variant="contained">Gerar PDF</Button>
                    <Button variant="contained">Gerar CSV</Button>
                </Grid>
            </Grid>
        </Container>

    )
}








