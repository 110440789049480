import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";

const createRoutineMachineLayer = (props) => {
  const {coorO, coorD} =  props

  const instance = L.Routing.control({
    waypoints: [
      L.latLng(coorO.latitude, coorO.longitude), // Asa Sul
      L.latLng(coorD.latitude, coorD.longitude), // Asa Norte
    ],
    lineOptions: {
      styles: [{ color: "#6FA1EC", weight: 6 }]
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: true,
    draggableWaypoints: true,
    fitSelectedRoutes: true,
    showAlternatives: false
  });
  // console.log(instance)
  return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
