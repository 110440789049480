import React from "react";
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, Typography, Stack, Breadcrumbs, Alert } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Service from "../../services/Service";
import { Link, useNavigate } from "react-router-dom";

export default function ConsolidadaTable() {
    const navigate = useNavigate();

    const [ufs, setUfs] = React.useState();
    const [wait, setWait] = React.useState(true);
    const [mensagem, setMensagem] = React.useState({ tipo: "", message: "mensagem não anexada" })
    const [openError, serOpenError] = React.useState(false)

    React.useEffect(() => {
        buscaDados();
    }, []);

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const breadcrumbs = [

        <Typography key="3" sx={{ color: 'text.primary' }}>
            Consolidado
        </Typography>,
    ];


    const handlerMkM = (r) => {
        if (r >= 1000) {
            return (r / 1000).toFixed(2) + " Km";
        } else {
            return r + " M";
        }
    }

    function buscaDados() {
        Service.GET("/api/consolidado/uf").then(r => {
            // console.log(r)
            setUfs(r);
            setWait(false);
        }).catch(error => {
            console.error(error)
            serOpenError(true)
            setMensagem({
                tipo: "error",
                message: "Falha ao listar tabela consolidada UF."
            })
            setWait(false);
        })
    }
    function calcFaltantes(res) {
        if (!res.totalProcessado || res.totalInscricoes === 0) return '0%';
        return (100 - (res.totalProcessado * 100 / res.totalInscricoes)).toFixed(2) + "%";
    }

    function per8km(res) {
        if (!res.abaixo8km || !res.totalProcessadoSemForasteiro) return '0%';
        return ((res.abaixo8km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function per8e15km(res) {
        if (!res.entre15e30km || !res.totalProcessadoSemForasteiro) return '0%';
        return ((res.entre15e30km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function perAc15km(res) {
        if (!res.acima30km || !res.totalProcessadoSemForasteiro) return '0%';
        // console.log(res.acima30km);
        return ((res.acima30km * 100) / res.totalProcessadoSemForasteiro).toFixed(2) + "%";
    }

    function provaGoogle(res) {
        if (!res.qtdGoogleProva || !res.totalProcessado) return '0%';
        return ((res.qtdGoogleProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function provaIBGE(res) {
        if (!res.qtdIBGEProva || !res.totalProcessado) return '0%';
        return ((res.qtdIBGEProva * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function alunoGoogle(res) {
        if (!res.qtdGoogleAluno || !res.totalProcessado) return '0%';
        return ((res.qtdGoogleAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }

    function alunoIBGE(res) {
        if (!res.qtdIBGEAluno || !res.totalProcessado) return '0%';
        return ((res.qtdIBGEAluno * 100) / res.totalProcessado).toFixed(2) + "%";
    }


    function handleDetailClick(item) {
        navigate("/consolidadoMuncipio/" + item.uf);

    };
    function formataNumero(number) {
        if (isNaN(number)) return "0";
        return number.toLocaleString("pt-BR");
    }
    return (
        <Container maxWidth="xl">
            <h1 style={{ textAlign: 'center' }}>Tabela Consolidado UF</h1>
            {openError &&
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity={mensagem.tipo}>
                        {mensagem.message}
                    </Alert>
                </Stack>
            }
            <Stack spacing={2} mb={2} ml={1}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={"medium"}>
                    <TableHead style={{ backgroundColor: "#044785" }}>
                        {/* Título geral da tabela */}
                        <TableRow>
                            <TableCell colSpan={5} style={{ borderRight: "1px solid white", color: "red", padding: "12px", textAlign: "center" }}>
                            </TableCell>
                            <TableCell colSpan={4} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Cálculos realizados desconsiderando os forasteiros
                            </TableCell>
                            <TableCell colSpan={2} style={{ borderRight: "1px solid white", color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Prova)
                            </TableCell>
                            <TableCell colSpan={3} style={{ color: "white", textAlign: "center" }}>
                                Origem Da Lat/Long (Aluno)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell style={{ color: "white", width: 150, borderRight: "1px solid lightgray" }}>UF</TableCell>
                            <TableCell style={{ color: "white", borderRight: "1px solid lightgray" }}>Total Inscrições</TableCell>
                            <TableCell style={{ color: "white", borderRight: "1px solid lightgray" }}>Total Processada</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>Inscrições Pendentes de Processamento</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Faltante</TableCell>
                            {/* calculo realizado */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>Distância Média</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Abaixo de 8 km</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Acima de 15km</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Acima de 30km</TableCell>
                            {/* prova */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Google</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Ibge/Local</TableCell>
                            {/* aluno */}
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Google</TableCell>
                            <TableCell style={{ color: "white", width: 160, borderRight: "1px solid lightgray" }}>% Ibge/Local</TableCell>
                            <TableCell style={{ color: "white", width: 160 }}>Detalhar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ufs?.map((resultado, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{resultado.uf}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{formataNumero(resultado.totalInscricoes)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{formataNumero(resultado.totalProcessado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{formataNumero(resultado.totalInscricoes - resultado.totalProcessado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{calcFaltantes(resultado)}</TableCell>
                                {/* nao fotasteiro */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{handlerMkM(resultado.distanciaMedia)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8km(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{per8e15km(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{perAc15km(resultado)}</TableCell>
                                {/* prova */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{provaIBGE(resultado)}</TableCell>
                                {/* aluno */}
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoGoogle(resultado)}</TableCell>
                                <TableCell style={{ borderRight: "1px solid lightgray" }}>{alunoIBGE(resultado)}</TableCell>
                                <TableCell >
                                    <RemoveRedEyeIcon style={{ cursor: 'pointer', margin: '5px' }} onClick={() => { handleDetailClick(resultado) }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={wait}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}