import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './styles.css';



const Dropzone = (props) => {
  const [selectedFile, setSelectedFile] = useState('');
  const { onFileUploaded } = props;

  const onDrop = useCallback(acceptedFiles => {

    const file = acceptedFiles[0];

    setSelectedFile(file.name)
    // console.log(file)
    // console.log(file.name)
    onFileUploaded(file);
  }, [onFileUploaded])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div className='dropzone' {...getRootProps()}>
      <input {...getInputProps()} />
      <div className='border'  >
        <div className='centered-img'>

          {selectedFile ? ("Arquivo selecionado: " + selectedFile) :
            <div className='center-container'>

              <div className='centered-div'>
                <img className='img-img' src='csv.256x254.png' />
                <div>
                  Arraste e solte seu arquivo .csv para validação do ensalamento
                </div>
                <br />
                <div className='button-container'>
                  <button className='button button-blue'>
                    Procure o arquivo .CSV
                  </button>

                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Dropzone;