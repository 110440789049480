import React from "react";
import './Footer.css'
export default function Footer() {
    return (
        <footer>

            <nav className="copyright">
                <a>© MEC - Ministério da Educação | INEP - Instituto Nacional de Estudos e Pesquisas Educacionais Anísio Teixeira</a>
            </nav>
        </footer >
    )
}