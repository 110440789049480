import React from "react";
import { Container, Box, Stack, TextField, LinearProgress, Button, 
    Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmDialog from "../util/ConfirmDialog";
import AlertDialog from "../AlertDialog";
import Service from "../../services/Service";
import Backdrop from '@mui/material/Backdrop';

export default function DetalheProcesso() {
    const [progress, setProgress] = React.useState(10);
    const TEMPO = 5000;
    const params = useParams();
    const navigate = useNavigate();
    const [nomeUF, setNomeUF] = React.useState('');
    const [nomeCidade, setNomeCidade] = React.useState('');
    
    const [status, setStatus] = React.useState('');
    const [tempo, setTempo] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [bloco, setBloco] = React.useState();
    const [openPausa, setOpenPausa] = React.useState(false);
    const [openCancelar, setOpenCancelar] = React.useState(false);
    const [openAlertCancelar, setOpenAlertCancelar] = React.useState(false);
    const [isPollingEnabled, setIsPollingEnabled] = React.useState(true);
    const timerIdRef = React.useRef(null);

    const [wait, setWait] = React.useState(false);

    React.useEffect(() => {
        const pollingCallback = () => {
          buscaBlocoPorID();
        };
        const startPolling = () => {
          timerIdRef.current = setInterval(pollingCallback, TEMPO);
        };
        const stopPolling = () => {
          clearInterval(timerIdRef.current);
        };
        if (isPollingEnabled) {
          startPolling();
        } else {
          stopPolling();
        }
        return () => {
          stopPolling();
        };
      }, [isPollingEnabled]);

    React.useEffect(()=>{
        buscaBlocoPorID();
    }, [])
    function buscaBlocoPorID(){
        if( !params.id) {
            setIsPollingEnabled(false);
            return;
        }
        setLoading(true)
        Service.GET('/api/settings/getByIdSettingBloco/'+params.id)
        .then(r => {
            console.log( r);
            setLoading(false)
            setBloco(r);
            montaFiltroUF(r);
            montaFiltroMunicipio(r);
            if( r.estado === 'CONCLUIDO'){
                if(r && r.isConsolidado) setStatus("Concluido");
                if( r.lockPid !== '') if(r && !r.isConsolidado) setStatus("Em execução");
            }
            // if(r && r.dtCriacao) gerarTempoAtivo(r.dtCriacao);
            if(r && r.dtAtualizacao) gerarTempoAtivo(r.dtAtualizacao);
            if( r && r.cancelado || r.pausado)setIsPollingEnabled( false);
            else  setIsPollingEnabled( r && r.estado === 'INICIADO');
            montaProcesso(r);
        });
    }
    function gerarTempoAtivo(d){
        console.log(">>>> ", d)
        const dataInicial = new Date(d);
        const dataAtual = new Date();
        const diffMilissegundos = dataAtual - dataInicial;

        const horas = Math.floor(diffMilissegundos / (1000 * 60 * 60));
        const segundos = Math.floor((diffMilissegundos % (1000 * 60 )) / 1000);


        setTempo(`${horas}h ${segundos}m`)
    }
    const handleRodar=()=>{
        Service.GET('/api/settings/rodar/'+params.id)
        .then(r => {
            window.location.reload();
            navigate("/detalharProcessos/"+params.id, { state: { key: "value" } });
        });
    }
    const handlePausa=()=>{
        setOpenPausa(true);
    }
    const handleCancelar=()=>{
        setOpenCancelar(true);
    }
    const reprocessarFaltantes = ()=>{
        setWait(true);
        Service.GET('/api/processo/reprocessarFaltantes/'+params.id)
        .then(r => {
            setWait(false);
            // console.log( r );
        });
    }
    const gerarConsolidados = ()=>{
        console.log("gerarConsolidados")
        setWait(true);
        Service.GET('/api/settings/reConsolida/'+params.id)
        .then(r => {
            setWait(false);
            navigate("/configurarProcessos/", { state: { key: "value" } });
        });
    }
    const handleExcluir=()=>{
        
        Service.GET('/api/settings/excluir/'+params.id)
        .then(r => {
            // window.location.reload();
            navigate("/configurarProcessos/", { state: { key: "value" } });
        });
    }
    function montaProcesso(item){
        if( !item || !item.mapBlocos){
            setProgress(0);
            return;
        }        
        const arr = item.mapBlocos;
        const last = item.mapBlocos[ item.mapBlocos.length -1]
        // console.log( last );
         
        if( last ) setProgress( (last.qtdCorrente * 100)/ last.total);
    }
    function montaFiltroUF(item){
        if( !item ) return;
        const filtro = item.filtro;
        let txt = '';        
        if( filtro && filtro.UF ){            
            for(let x in filtro.UF){
                txt += filtro.UF[x]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        setNomeUF(txt);
    }
    function montaFiltroMunicipio(item){
        if( !item ) return;
        const filtro = item.filtro;
        let txt = '';        
        if( filtro && filtro.CIDADE ){            
            for(let x in filtro.CIDADE){
                txt += item[filtro.CIDADE[x]]+", ";
            }
            txt = txt.substring( 0, txt.length-2);
        }else{
            txt = 'Todos'
        }
        setNomeCidade( txt );        
    }
    function pausa(){
        Service.GET('/api/settings/pausa/'+params.id)
        .then(r => {
            
        });
    }
    function cancelar(){
        Service.GET('/api/settings/cancela/'+params.id)
        .then(r => {
            setOpenAlertCancelar(true);
        });
    }
    function formataData(dt){
        if( !dt) return '';
        return new Date(dt).toLocaleString('pt-BR', { timezone: 'UTC' })
    }
    if (loading) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '5vh', 
                    paddingTop: '100px'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <Container fixed>
            <Box                 
                my={4}
                display="flex"
                gap={4}
                p={2}
                sx={{ border: '1px solid grey' }}
            >
                
                <Stack spacing={2} sx={{ width: '100vw' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Data e hora: {bloco && formataData( bloco.dtCriacao )}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Responsável: {bloco && bloco.user?bloco.user:'Sistema'}
                    </Typography>
                    <TextField
                        label="UF:"                     
                        InputProps={{
                            readOnly: true,
                        }}
                        value={nomeUF}
                        fullWidth
                    />
                    <TextField
                        label="CIDADE:"                     
                        InputProps={{
                            readOnly: true,
                        }}
                        value={nomeCidade}
                        fullWidth
                    />
                    {/* <TextField
                        id="inputMunicipio"
                        label="MUNICÍPIO:"
                        defaultValue="????"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    /> */}
                    { bloco && bloco.estado === 'INICIADO' &&
                    <React.Fragment>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        {!bloco.cancelado && !bloco.pausado && 
                        <div>
                            <Button variant="outlined" onClick={handlePausa}>Pausar</Button>
                            {/* <Button variant="outlined" onClick={handleCancelar}>Cancelar</Button> */}
                        </div>
                        }
                        { bloco.pausado && !bloco.cancelado &&
                        <div>
                            <Button variant="outlined" onClick={handleRodar}>Rodar</Button>
                            {/* <Button variant="outlined" onClick={handleCancelar}>Cancelar</Button> */}
                            <Button variant="outlined" onClick={handleExcluir}>Excluir</Button>
                        </div>
                        }
                    </React.Fragment>
                    }
                    <Button variant="outlined" onClick={reprocessarFaltantes}>Reprocessar faltantes</Button>
                    { bloco && bloco.estado === 'CONCLUIDO' && 
                    <React.Fragment>
                        <TextField
                            label="PID:"                     
                            InputProps={{
                                readOnly: true,
                            }}
                            value={bloco && bloco.lockPid}
                            // fullWidth
                        />
                        <TextField
                            label="status do consolidado:"                     
                            InputProps={{
                                readOnly: true,
                            }}
                            value={status}
                            // fullWidth
                        />
                        <TextField
                            label="tempo de execução do consolidado:"                     
                            InputProps={{
                                readOnly: true,
                            }}
                            value={tempo}
                            // fullWidth
                        />
                        <Button variant="outlined" onClick={gerarConsolidados}>Gerar Consolidado</Button>
                    </React.Fragment>
}
                </Stack>
            </Box>
            <ConfirmDialog open={openPausa} setOpen={setOpenPausa} 
                title='Pausar processamento' 
                btnLabel='Sim' btn2Label='Cancelar'
                actionOnClick={pausa}
                >
                Tem certeza que deseja pausar processamento?
            </ConfirmDialog>
            <ConfirmDialog open={openCancelar} setOpen={setOpenCancelar} 
                title='Pausar processamento' 
                btnLabel='Sim' btn2Label='Cancelar'
                actionOnClick={cancelar}
                >
                Tem certeza que deseja cancelar processamento?
            </ConfirmDialog>

            <AlertDialog open={openAlertCancelar} 
                setOpen={setOpenAlertCancelar} 
                title='Confirmação' 
                btnLabel='fechar'
                actionOnClick={()=>setOpenAlertCancelar(false)}
                >
                Processamento cancelado com sucesso.
            </AlertDialog>

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={wait}                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}